import buildQuery from 'odata-query';

export const SET_LOADING = 'reiseterminComments.setLoading';

export const SET_COMMENTS = 'reiseterminComments.setComments';
export const GET_COMMENTS = 'reiseterminComments.getComments';
export const SET_REISETERMIN = 'reiseterminComments.setReisetermin';

export const ADD_COMMENT = 'reiseterminComments.addComment';
export const SAVE_COMMENT = 'reiseterminComments.saveComment';
export const UPDATE_OR_SAVE_COMMENT = 'reiseterminComments.updateOrSaveComment';

export const UPDATE_COMMENT = 'reiseterminComments.updateComment';
export const ADD_COMMENT_ANSWER = 'reiseterminComments.addCommentAnswer';

export const SET_COMMENT_ANSWERS = 'reiseterminComments.setCommentAnswers';

export const REPLY_TO_COMMENT_BY_ID = 'reiseterminComments.replyToCommentById';
export const UPDATE_COMMENT_BY_ID = 'reiseterminComments.updateCommentById';
export const REQUEST_DELETE_COMMENT = 'reiseterminComments.requestDeleteComment';
export const DELETE_COMMENT = 'reiseterminComments.deleteComment';

export const SET_COMMENT_EVENT_MESSAGE = 'reiseterminComments.setCommentEventMessage';

export const SET_CURRENTLY_EDITING_COMMENT_OBJECT = 'reiseterminComments.setCurrentlyEditingCommentObject';
export const SET_CURRENTLY_EDITING_COMMENT_TYPE = 'reiseterminComments.setCurrentlyEditingCommentType';

export const STOP_EDITING_COMMENT = 'reiseterminComments.stopEditingComment';
export const START_EDITING_COMMENT = 'reiseterminComments.startEditingComment';

export const SET_COMMENT_HTML_BY_ID = 'reiseterminComments.setCommentHtmlById';

export const MARK_COMMENT = 'reiseterminComments.markComment';
export const UNMARK_COMMENT = 'reiseterminComments.unmarkComment';

import apiService from '@/core/common/services/api.service';

const state = {
  isLoading: false,
  comments: [],
  currentlyEditingCommentObject: null,
  currentlyEditingCommentType: null, // is either reply or edit
  currentCommentText: '',
  commentEventMessage: {
    text: '',
    options: {},
  },
  reisetermin: { id: null, reisekuerzel: null, startdatum: null },
};

const actions = {
  [DELETE_COMMENT](context, id) {
    // TODO: Fix later and remove answers from comments
    // only use answers in components
    // this is only temporary
    context.commit(SET_CURRENTLY_EDITING_COMMENT_OBJECT, null);
    return context.dispatch(GET_COMMENTS, {
      id: context.state.reisetermin.id,
      reisekuerzel: context.state.reisetermin.reisekuerzel,
      startdatum: context.state.reisetermin.startdatum,
    });
  },
  [MARK_COMMENT](context, { id, reisekuerzel, startdatum }) {
    return apiService
      .put(`Reise/${reisekuerzel}/Reisetermin/${startdatum}/Comment/${id}/mark`)
      .then(response => {
        console.log(response);
        context.dispatch(GET_COMMENTS, { id: context.state.reisetermin.id, reisekuerzel, startdatum });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        context.commit(SET_LOADING, false);
      });
  },
  [UNMARK_COMMENT](context, { id, reisekuerzel, startdatum }) {
    return apiService
      .delete(`Reise/${reisekuerzel}/Reisetermin/${startdatum}/Comment/${id}/mark`)
      .then(response => {
        console.log(response);
        context.dispatch(GET_COMMENTS, { id: context.state.reisetermin.id, reisekuerzel, startdatum });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        context.commit(SET_LOADING, false);
      });
  },
  [ADD_COMMENT](context, comment) {
    const formattedComment = formatComments([comment]);
    let comments = [...context.state.comments];
    comments.unshift(formattedComment[0]);
    return context.commit(SET_COMMENTS, comments);
  },
  [ADD_COMMENT_ANSWER](context, { parentId, answerObject }) {
    const parentCommentIndex = context.state.comments.findIndex(comment => comment.id === parentId);
    let comments = [...context.state.comments];
    let [removedParentComment] = comments.splice(parentCommentIndex, 1);
    removedParentComment.answers.unshift(answerObject);
    comments.unshift(removedParentComment);
    return context.commit(SET_COMMENTS, comments);
  },
  [UPDATE_OR_SAVE_COMMENT](context, { id, reisekuerzel, startdatum, text }) {
    context.commit(SET_LOADING, true);
    // Check if we are adding a new comment or editing or replying
    if (context.state.currentlyEditingCommentObject) {
      // Check if we are editing or replying
      if (context.state.currentlyEditingCommentType === 'reply') {
        // We are replying
        context.dispatch(REPLY_TO_COMMENT_BY_ID, {
          parentId: context.state.currentlyEditingCommentObject.id,
          reiseterminId: id,
          text,
        });
      } else if (context.state.currentlyEditingCommentType === 'edit') {
        // We are editing
        context.dispatch(UPDATE_COMMENT_BY_ID, {
          id: context.state.currentlyEditingCommentObject.id,
          reisekuerzel,
          startdatum,
          text,
        });
      }
    } else {
      // We are adding a new comment
      context.dispatch(SAVE_COMMENT, {
        id,
        text,
      });
    }
  },
  [GET_COMMENTS](context, { id, reisekuerzel, startdatum }) {
    context.commit(SET_REISETERMIN, { id, reisekuerzel, startdatum });
    context.commit(SET_LOADING, true);
    const odataQuery = buildQuery({ filter: { reiseterminId: id } });
    return apiService
      .get(`Comments/` + odataQuery)
      .then(response => {
        context.commit(SET_COMMENTS, formatCommentsNew(response.data.result.value));
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        context.commit(SET_LOADING, false);
      });
  },
  [START_EDITING_COMMENT](context, { commentObject, commentType }) {
    context.commit(SET_CURRENTLY_EDITING_COMMENT_OBJECT, commentObject);
    context.commit(SET_CURRENTLY_EDITING_COMMENT_TYPE, commentType);
  },
  [STOP_EDITING_COMMENT](context) {
    context.commit(SET_CURRENTLY_EDITING_COMMENT_OBJECT, null);
    context.commit(SET_CURRENTLY_EDITING_COMMENT_TYPE, null);
  },
  async [SAVE_COMMENT](context, { id, text }) {
    apiService
      .post('/Comments', {
        reiseterminId: id,
        text: text,
      })
      .then(response => {
        context.dispatch(ADD_COMMENT, response.data.result);
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: 'Kommentar gespeichert',
          options: {
            autoHideDelay: 1000,
            variant: 'success',
            noCloseButton: false,
          },
        });
      })
      .catch(err => {
        console.error(err);
        console.error(err.response.data.responseException.exceptionMessage);
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: err.response.data.responseException.exceptionMessage.errors,
          options: {
            autoHideDelay: 1000,
            variant: 'danger',
            noCloseButton: false,
          },
        });
      })
      .finally(() => {
        context.commit(SET_LOADING, false);
      });
  },
  async [UPDATE_COMMENT_BY_ID](context, { id, reisekuerzel, startdatum, text }) {
    apiService
      .put('/Comments?commentId=' + id, {
        commentId: id,
        text: text,
      })
      .then(response => {
        console.log(response);
        context.dispatch(GET_COMMENTS, { id: context.state.reisetermin.id, reisekuerzel, startdatum });
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: 'Kommentar gespeichert',
          options: {
            autoHideDelay: 1000,
            variant: 'success',
            noCloseButton: false,
          },
        });
      })
      .catch(err => {
        console.error(err.response.data.responseException.exceptionMessage);
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: err.response.data.responseException.exceptionMessage.errors,
          options: {
            autoHideDelay: 1000,
            variant: 'danger',
            noCloseButton: false,
          },
        });
      })
      .finally(() => {
        context.commit(SET_CURRENTLY_EDITING_COMMENT_OBJECT, null);
        context.commit(SET_CURRENTLY_EDITING_COMMENT_TYPE, null);
        context.commit(SET_LOADING, false);
      });
  },
  async [REPLY_TO_COMMENT_BY_ID](context, { parentId, reiseterminId, text }) {
    apiService
      .post('/Comments', {
        reiseterminId,
        parentId,
        text,
      })
      .then(response => {
        console.log(response);
        context.dispatch(ADD_COMMENT_ANSWER, { parentId, answerObject: response.data.result });
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: 'Antwort gesendet',
          options: {
            autoHideDelay: 1000,
            variant: 'success',
            noCloseButton: false,
          },
        });
      })
      .catch(err => {
        console.log(err);
        console.error(err.response.data.responseException.exceptionMessage);
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: err.response.data.responseException.exceptionMessage.errors,
          options: {
            autoHideDelay: 1000,
            variant: 'danger',
            noCloseButton: false,
          },
        });
      })
      .finally(() => {
        context.commit(SET_CURRENTLY_EDITING_COMMENT_OBJECT, null);
        context.commit(SET_CURRENTLY_EDITING_COMMENT_TYPE, null);
        context.commit(SET_LOADING, false);
      });
  },
  async [REQUEST_DELETE_COMMENT](context, { commentObject }) {
    context.commit(SET_LOADING, true);
    apiService
      .delete('/Comments?commentId=' + commentObject.id, {
        commentId: commentObject.id,
      })
      .then(response => {
        context.dispatch(DELETE_COMMENT, commentObject.id);
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: 'Kommentar gelöscht',
          options: {
            autoHideDelay: 1000,
            variant: 'success',
            noCloseButton: false,
          },
        });
      })
      .catch(err => {
        console.log(err);
        console.error(err.response.data.responseException.exceptionMessage);
        context.commit(SET_COMMENT_EVENT_MESSAGE, {
          text: err.response.data.responseException.exceptionMessage.errors,
          options: {
            autoHideDelay: 1000,
            variant: 'danger',
            noCloseButton: false,
          },
        });
      })
      .finally(() => {
        context.commit(SET_CURRENTLY_EDITING_COMMENT_OBJECT, null);
        context.commit(SET_LOADING, false);
      });
  },
};

function formatComments(unformattedComments) {
  return unformattedComments.map(comment => {
    const currentComment = comment.hasOwnProperty('parentComment') ? comment.parentComment : comment;
    return {
      ...currentComment,
      answers: comment.hasOwnProperty('parentComment') ? comment.answers : [],
      html: currentComment.text,
    };
  });
}
function formatCommentsNew(unformattedComments) {
  const parentComments = unformattedComments.filter(comment => comment.parentId === null);
  const childComments = unformattedComments.filter(comment => comment.parentId !== null);
  return parentComments.map(comment => ({
    ...comment,
    html: comment.text,
    answers: childComments.filter(child => child.parentId === comment.id),
  }));
}
const mutations = {
  [SET_LOADING](state, loadingState) {
    state.isLoading = loadingState;
  },
  [SET_COMMENTS](state, comments) {
    state.comments = comments;
  },
  [UPDATE_COMMENT](state, { id, text }) {
    const updatedCommentIndex = state.comments.findIndex(comment => comment.id === id);
    const updatedComment = { ...state.comments[updatedCommentIndex], text: text };
    const formattedUpdatedComment = formatComments([updatedComment]);
    state.comments.splice(updatedCommentIndex, 1, formattedUpdatedComment[0]);
  },
  [SET_COMMENT_ANSWERS](state, { parentId, answers }) {
    const parentCommentIndex = state.comments.findIndex(comment => comment.id === parentId);
    state.comments[parentCommentIndex].answers = answers;
  },
  [SET_REISETERMIN](state, { id, reisekuerzel, startdatum }) {
    state.reisetermin = { id, reisekuerzel, startdatum };
  },
  [SET_CURRENTLY_EDITING_COMMENT_OBJECT](state, commentObject) {
    state.currentlyEditingCommentObject = commentObject;
  },
  [SET_CURRENTLY_EDITING_COMMENT_TYPE](state, type) {
    // is either reply or edit
    state.currentlyEditingCommentType = type;
  },
  [SET_COMMENT_HTML_BY_ID](state, { id, html }) {
    let tmpCommentIndex = state.comments.findIndex(c => c.id === id);
    state.comments[tmpCommentIndex].html = html;
  },
  [SET_COMMENT_EVENT_MESSAGE](state, msg) {
    state.commentEventMessage = msg;
  },
};

const getters = {
  getCommentsCount: state => {
    return state.comments.filter(comment => !comment.parentId).length || 0;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
